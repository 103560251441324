<template>
  <div v-if="availability" class="*:flex *:gap-[.35em]" :title="availability.deliveryPhrase">
    <div v-if="availability.stockStatus === 'inStock'" class="text-green-500">
      <CheckCircleIcon class="size-[1.5em]" />
      <span data-jest="stock-status-text">
        Varastossa
      </span>
    </div>
    <div v-else-if="availability.stockStatus === 'backorder'" class="text-yellow-600">
      <ClockIcon class="size-[1.5em] text-yellow-400" />
      <span data-jest="stock-status-text">
        Tilattavissa
      </span>
    </div>
    <div v-else-if="availability.stockStatus === 'outOfStock'" class="text-red-700">
      <XCircleIcon class="size-[1.5em]" />
      <span data-jest="stock-status-text">
        Loppu varastosta
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckCircleIcon, XCircleIcon, ClockIcon } from '@heroicons/vue/24/solid'
import { useStock } from '~/composables/useStock'
import type { ProductAlgolia, ProductTileAlgolia, ProductTileMiniAlgolia, EcomAvailabilityInfo } from '~/types/product'
import type { CartItem } from '~/types/item'

const props = defineProps({
  item: {
    type: Object as () => ProductTileMiniAlgolia | ProductTileAlgolia | ProductAlgolia | CartItem | null,
    required: true
  }
})

const availability: ComputedRef<EcomAvailabilityInfo | null> = computed(() => {
  if (!props.item?.stock) {
    return null
  }
  const { getEcomAvailabilityInfo } = useStock()
  return getEcomAvailabilityInfo(props.item.stock, props.item.erpinfo as ProductAlgolia['erpinfo'])
})
</script>
