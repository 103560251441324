import { defineStore } from 'pinia'
// import { calculateCustomizationIdentifier, recalculateCustomizationPrice } from '~/helpers/customization'
// import { attributesForProductTileMini } from '~/helpers/instantsearch'
import type { ProductState, ProductAlgolia } from '~/types/product'
// import type { Customization, CustomSize, CustomUpholstery, CustomizationWithIdentifier, CustomizationWithPrice } from '~/types/customization'

export const useProductStore = defineStore('product', {
  state: (): ProductState => ({
    currentProduct: null,
    currentProductVariations: [],
    // currentProductRelated: [],
    lightBoxInitSlideNumber: 0,
    // customization: {
    //   properties: {}
    // },
    customPropertyValidationErrors: [],
    lightBoxStatus: false,
    pixmoorModalStatus: false,
    videoModalStatus: false
  }),
  getters: {
    // getCurrentProduct: state => state.currentProduct,
    // getCurrentProductVariations: state => state.currentProductVariations,
    // getCurrentProductRelated: state => state.currentProductRelated || [],
    // getLightBoxInitSlideNumber: state => state.lightBoxInitSlideNumber,
    currentProductImages: state => state.currentProduct?.images,
    currentProductReviewImages: state => state.currentProduct?.reviewImages,
    // customization: state => state.customization,
    // customSize: state => state.customization?.properties?.size,
    // customUpholstery: state => state.customization?.properties?.upholstery,
    hasCustomizationError: state => state.customPropertyValidationErrors.length > 0,
    // customSizeError: state => state.customPropertyValidationErrors.includes('size'),
    // customUpholsteryError: state => state.customPropertyValidationErrors.includes('upholstery'),
    currentProductMainVariation: state => state.currentProductVariations.find(cpv => cpv.isMainVariation),
    variationsOnDisplay: state => (storeCode: string) => state.currentProductVariations.filter(cpv => cpv.stock.storeAvailability[storeCode] && cpv.stock.storeAvailability[storeCode].onDisplayStock > 0)
  },
  actions: {
    async fetchCurrentProductVariations() {
      if (this.currentProduct && !this.currentProductVariations.some(v => v.sku === this.currentProduct?.sku)) {
        this.currentProductVariations = []
        const { result, search } = useAlgoliaSearch()
        await search({ query: '', requestOptions: { filters: `parentSku:'${this.currentProduct.parentSku}'`, attributesToHighlight: [], hitsPerPage: 1000 } })
        if (result.value?.hits) {
          this.currentProductVariations = result.value?.hits as ProductAlgolia[]
        }
      }
    },
    async fetchCurrentProductBySlug(slug: string) {
      const { data } = await useAsyncAlgoliaSearch({ key: 'fetch_current_product_by_slug', query: '', requestOptions: { filters: `slug:'${slug}'`, attributesToHighlight: [], hitsPerPage: 1 } })
      this.setCurrentProduct(data.value?.hits[0] as ProductAlgolia)
      return data.value?.hits[0] as ProductAlgolia
    },
    async openProduct(payload: { slug: string, product: ProductAlgolia }) {
      if (payload.product) {
        await this.setCurrentProduct(payload.product)
      }
      const slug = payload.slug || payload.product?.slug
      if (slug) {
        useRouter().push('/product/' + slug)
      }
    },
    setLightBoxSlideNumber(payload: number) {
      this.lightBoxInitSlideNumber = payload
    },
    removeCurrentProduct() {
      this.currentProduct = null
    },
    removeCurrentProductVariations() {
      this.currentProductVariations = []
    },
    setCurrentProduct(product: ProductAlgolia | null) {
      this.currentProduct = product
    },
    setLightBoxStatus(payload: boolean) {
      this.lightBoxStatus = payload
    },
    setPixmoorModalStatus(payload: boolean) {
      this.pixmoorModalStatus = payload
    },
    setVideoModalStatus(payload: boolean) {
      this.videoModalStatus = payload
    }
    // setCustomSize(payload: CustomSize) {
    //   this.customization.properties.size = payload
    //   this.setCurrentProductCustomization()
    // },
    // setCustomUpholstery(payload: CustomUpholstery) {
    //   this.customization.properties.upholstery = payload
    //   this.setCurrentProductCustomization()
    // },
    // setCustomSizeValidationError(payload: boolean) {
    //   if (payload) {
    //     if (!this.customPropertyValidationErrors.includes('size')) {
    //       this.customPropertyValidationErrors.push('size')
    //     }
    //   }
    //   else {
    //     this.customPropertyValidationErrors = this.customPropertyValidationErrors.filter(item => item !== 'size')
    //   }
    // },
    // setCustomUpholsteryValidationError(payload: boolean) {
    //   if (payload) {
    //     if (!this.customPropertyValidationErrors.includes('upholstery')) {
    //       this.customPropertyValidationErrors.push('upholstery')
    //     }
    //   }
    //   else {
    //     this.customPropertyValidationErrors = this.customPropertyValidationErrors.filter(item => item !== 'upholstery')
    //   }
    // },
    // setCurrentProductCustomization() {
    //   if (this.currentProduct) {
    //     const customization: CustomizationWithIdentifier = { ...this.customization, identifier: calculateCustomizationIdentifier(this.currentProduct.sku, this.customization) }
    //     const customizationWithPrice: CustomizationWithPrice = recalculateCustomizationPrice(this.currentProduct.finalPrice.inclTax, this.currentProduct.price.inclTax, customization)

    //     this.currentProduct.customization = customizationWithPrice
    //   }
    // },
    // removeCustomProperty(payload: string) {
    //   delete this.customization.properties[payload]
    // },
    // resetCustomization() {
    //   this.customization = { properties: {} }
    //   if (this.currentProduct) {
    //     delete this.currentProduct.customization
    //   }
    // }
  }
})
